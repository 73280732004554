import React from 'react';


export const Logo: React.FC = () => {
  return <svg width="131" height="27" viewBox="0 0 131 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1570_40051)">
      <path d="M50.9993 7.71542C49.7845 7.71542 48.8951 8.23152 48.3311 9.02717V7.93046H45.6846V16.9836H48.3311V11.2636C48.6782 10.4894 49.3289 10.0594 50.0448 10.0594C50.9993 10.0594 51.6284 10.5755 51.6284 11.8227V16.9836H54.2315V11.2851C54.2315 8.85514 52.8648 7.71542 50.9993 7.71542Z" fill="#322578"/>
      <path d="M59.1992 7.93045H56.5527V16.9836H59.1992V7.93045Z" fill="#322578"/>
      <path d="M70.8696 7.93045H68.2231V16.9836H70.8696V7.93045Z" fill="#322578"/>
      <path d="M75.3601 11.3926C75.4469 10.2744 76.2929 9.7153 77.1823 9.7153C78.0717 9.7153 78.9177 10.2529 78.9828 11.3926H75.3601ZM81.651 12.1452C81.5642 9.41424 79.7854 7.71542 77.269 7.71542C74.8395 7.71542 72.6919 9.45725 72.6919 12.4248C72.6919 15.5644 74.9262 17.1987 77.4643 17.1987C79.46 17.1987 80.8266 16.4245 81.5642 15.1343L79.7637 13.9301C79.2865 14.7042 78.5272 15.1558 77.5944 15.1558C76.3796 15.1558 75.4252 14.3817 75.3167 13.0914H81.6726C81.6726 12.9194 81.6726 12.6828 81.651 12.1452Z" fill="#322578"/>
      <path d="M89.352 12.9624C89.3303 14.1236 88.5276 14.7902 87.5298 14.7902C86.5102 14.7902 85.5991 14.0591 85.5991 12.4033C85.5991 10.769 86.4885 10.0378 87.5298 10.0378C88.5493 10.0378 89.3303 10.7475 89.352 11.8442V12.9624ZM89.352 8.51106C88.7663 8.03797 87.9853 7.75842 87.0525 7.75842C84.9267 7.75842 82.9526 9.32821 82.9526 12.4463C82.9526 15.5644 84.9484 17.1557 87.0742 17.1557C88.0938 17.1557 88.9615 16.7256 89.5472 16.145V17.0266H91.9768V3.95221H89.3303L89.352 8.51106Z" fill="#322578"/>
      <path d="M98.8964 14.8118C97.8986 14.8118 97.0742 14.1236 97.0742 13.0054V11.8872C97.0959 10.769 97.8986 10.0809 98.8964 10.0809C99.8943 10.0809 100.827 10.812 100.827 12.4678C100.805 14.0806 99.8943 14.8118 98.8964 14.8118ZM103.495 12.4463C103.495 9.32823 101.543 7.73694 99.3737 7.73694C98.289 7.73694 97.443 8.14551 96.879 8.81214V7.93047H94.4277V20.4243H97.0742V16.274C97.6599 16.7901 98.4409 17.1342 99.3737 17.1342C101.478 17.1342 103.495 15.5429 103.495 12.4463Z" fill="#322578"/>
      <path d="M109.461 14.8762C108.398 14.8762 107.444 14.0161 107.444 12.4033C107.444 10.812 108.398 10.0163 109.461 10.0163C110.524 10.0163 111.478 10.8335 111.478 12.4033C111.478 14.0376 110.546 14.8762 109.461 14.8762ZM109.461 7.69391C107.097 7.69391 104.775 9.34972 104.775 12.4033C104.775 15.4999 107.01 17.1772 109.461 17.1772C111.847 17.1772 114.147 15.4784 114.147 12.4033C114.147 9.32821 111.912 7.69391 109.461 7.69391Z" fill="#322578"/>
      <path d="M123.062 14.2526C123.062 12.3818 121.609 11.6507 119.808 11.3926C118.68 11.2206 117.986 11.0916 117.986 10.4894C117.986 9.95184 118.485 9.60778 119.266 9.60778C120.069 9.60778 120.589 9.93034 120.784 10.7045L123.019 10.1454C122.607 8.74762 121.479 7.71542 119.288 7.71542C117.075 7.71542 115.448 8.83363 115.448 10.597C115.448 12.3173 116.728 13.0484 118.594 13.371C119.722 13.543 120.481 13.7796 120.481 14.3817C120.481 14.9623 119.873 15.2418 119.114 15.2418C118.181 15.2418 117.574 14.7687 117.292 13.8871L115.058 14.5322C115.513 16.2525 116.901 17.1772 119.114 17.1772C121.457 17.1557 123.062 16.016 123.062 14.2526Z" fill="#322578"/>
      <path d="M40.63 13.3495C40.63 14.3601 40.0443 14.9838 39.0682 14.9838C38.1137 14.9838 37.4629 14.2526 37.4629 13.1989V7.93045H34.8164V13.3495C34.8164 14.5967 35.1418 15.5214 35.836 16.1235C36.5735 16.8116 37.7232 17.1772 39.0682 17.1772C40.3263 17.1772 41.411 16.8331 42.1919 16.188C42.9728 15.5214 43.2765 14.7472 43.2765 13.3495V7.93045H40.63V13.3495Z" fill="#322578"/>
      <path d="M62.1711 7.43587V7.93046H60.8262V10.0809H62.1928V16.9621H64.8176V10.0809H66.7916V7.93046H64.8176V7.43587C64.8176 6.64022 65.1647 6.18864 66.0758 6.18864H66.7916V3.8017H66.0758C63.4293 3.8017 62.1711 4.8984 62.1711 7.43587Z" fill="#322578"/>
      <path d="M70.913 3.8017H68.2231V6.18864H70.913V3.8017Z" fill="#322578"/>
      <path d="M59.1992 3.8017H56.5093V6.18864H59.1992V3.8017Z" fill="#322578"/>
      <path d="M129.657 16.7256V14.6397H128.941C128.052 14.6397 127.705 14.2096 127.705 13.414V10.1024H130.004V7.95196H127.726V5.522H125.167V7.93045H123.843V10.0809H125.145V13.3925C125.145 15.4784 126.034 16.5751 127.835 16.8761C128.833 17.0266 129.657 16.7256 129.657 16.7256Z" fill="#322578"/>
      <path d="M1.49659 17.6288L5.94358 25.2412L16.4211 20.8759L20.4776 9.52176L17.2888 4.03824L10.0218 1.50077L1.49659 5.02742V17.6288ZM5.37957 26.94L0.17334 17.9943V4.14576L9.97841 0.0599976L18.1999 2.94153L21.9527 9.39273L17.4841 21.9081L5.37957 26.94Z" fill="#322578"/>
    </g>
    <defs>
      <clipPath id="clip0_1570_40051">
        <rect width="130.156" height="26.88" fill="white" transform="translate(0 0.0599976)"/>
      </clipPath>
    </defs>
  </svg>
};
