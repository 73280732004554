import { useKeycloak } from '@react-keycloak/web';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../../state/hooks';
import { useSelector } from 'react-redux';
import { Button, Card, CardActions, CardContent, CardHeader, Grid, Modal, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { BackgroundCardShape } from '../atoms';
import { credentialSelector, verifyCredential } from '../../state/slices/credential';
import { customerSelector, getCustomer } from '../../state';

export const ShowCustomer: FC = () => {
    const dispatch = useAppDispatch();
    const { keycloak, initialized } = useKeycloak();
    const [walletUrl, setWalletUrl] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);

    const customer = useSelector(customerSelector);
    let navigate = useNavigate();

    useEffect(() => {
        dispatch(getCustomer({jwtToken: keycloak.token!})) .then((data) => {
                if (data.type.includes('fulfilled')) {
                    if (!data.payload) {
                        window.location.replace('/register');
                    } else {
                        setLoading(false);
                    }
                }
            }
        );
    }, [keycloak.token]);

    return (
        !loading && (customer.singleItem !== undefined) ?
        <div>
            <Grid container spacing={2} flexDirection={'row'}>
                <Grid item xs={12}>
                    <Card sx={{background: '#302673', color: '#ffffff', borderRadius: 8, padding: 3}} variant="outlined" >
                        <CardHeader sx={{fontFamily: 'Batonturboweb Bold, sans-serif', fontSize: '2rem', fontWeight: 700}} title='Company profile'/>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    Naam:
                                </Grid>
                                <Grid item xs={8}>
                                    {customer.singleItem?.naam}
                                </Grid>
                                <Grid item xs={4}>
                                    KvK nummer:
                                </Grid>
                                <Grid item xs={8}>
                                    {customer.singleItem?.kvkNummer}
                                </Grid>
                                <Grid item xs={4}>
                                    BTW nummer:
                                </Grid>
                                <Grid item xs={8}>
                                    {customer.singleItem?.btwNummer}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div> : <></>
    );
};
