import { createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../global';

import { bearerAuth } from '../auth';
import axios from 'axios';


export const verifyCredential = createAsyncThunk(
        'credential/verifyCredential', ({jwtToken, walletUrl}: {jwtToken: string | undefined, walletUrl: string}, thunkAPI) => {
            thunkAPI.dispatch(setLoading(true));
            const config = {
                headers: {'Authorization': bearerAuth(jwtToken)}
            };

            return axios.post('/manage/credential/exchange?walletUrl=' + walletUrl, {}, config)
                .then(response => {
                    console.log('QQQ response: ', response);
                    return response.data
                })
                .finally(() => {
                    thunkAPI.dispatch(setLoading(false));
                });
        },
);
