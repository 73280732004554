import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericState, GenericState } from '../model';
import { addGenericStateSingleBuilders } from '../slice';
import { verifyCredential } from './CredentialApi';


export interface Credential {
    oidcUrl: string;
}


export interface CredentialState extends GenericState<Credential> {
}

export const credentialSlice = createSlice({
    name: 'credential',
    initialState: defaultGenericState,
    reducers: {},
    extraReducers: builder => {
        addGenericStateSingleBuilders(builder, verifyCredential);
    },
});
