import { useKeycloak } from '@react-keycloak/web';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch } from '../../state/hooks';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { customerSelector, getCustomer, registerCustomer } from '../../state';
import { LinearProgress } from '@mui/material';

export const RegisterSuccessCustomer: FC = () => {
    const dispatch = useAppDispatch();
    const { keycloak, initialized } = useKeycloak();
    const customerState = useSelector(customerSelector);
    let navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('id');

    useEffect(() => {
        dispatch(registerCustomer({jwtToken: keycloak.token!, sessionId: sessionId!})) .then((data) => {
                if (data.type.includes('fulfilled')) {
                    navigate('/')
                }
            }
        );
    }, [keycloak.token]);

    if (sessionId) {
        return (
            <div>
                <LinearProgress/>
            </div>
        );
    } else {
        return (<div>Invalid parameters.</div>);
    }
};
