import { createSlice } from '@reduxjs/toolkit';
import { defaultGenericState, GenericState } from '../model';
import { addGenericStateSingleBuilders } from '../slice';
import { getCustomer } from './CustomerApi';


export interface Customer {
    kvkNummer: string;
    naam: string;
    btwNummer: string;
}

export interface CustomerState extends GenericState<Customer> {
}

export const customerSlice = createSlice({
    name: 'customer',
    initialState: defaultGenericState,
    reducers: {},
    extraReducers: builder => {
        addGenericStateSingleBuilders(builder, getCustomer);
    },
});
